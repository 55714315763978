.error-messages {
  background-color: #ff6b6b;
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.error-message {
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.error-message:last-child {
  margin-bottom: 0;
}

.send-emails-container {
  width: calc(100% - 300px);
  height: calc(100% - 100px);
  padding: 20px;
  background-color: #292929;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.send-emails-container .to,
.send-emails-container .subject {
  background-color: transparent;
  outline: none;
  border: none;
  border-bottom: 1px solid #fff;
  padding: 10px;
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff88;
}

.send-emails-container .to {
  color: #ffffff;
}

.send-emails-container .email-area {
  height: 100%;
  background-color: transparent;
  outline: none;
  border: none;
  resize: none;
  padding: 10px;
  font-size: 1rem;
  font-weight: normal;
  color: #fff;
}

.app-code-container {
  display: flex;
  align-items: center;
  /* margin-bottom: 20px; */
}

.send-emails-container .app-code {
  width: 250px;
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  /* background-color: rgba(255, 255, 255, 0.1); */
  background: transparent;
  color: #fff;
  font-size: 1rem;

  /* font-family: 'password'; */
}

.send-emails-container .app-code::placeholder {
  color: #ffffff88;
}

.tooltip-container {
  position: relative;
  display: inline-block;
  
}

.question-mark-btn {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question-mark-btn img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: invert(1);
}

.question-mark-btn:hover {
  opacity: 0.8;
}

.tooltip {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.tooltip-container:hover .tooltip {
  opacity: 1;
}

.tooltip::before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #333 transparent;
}

.send-emails-container .send-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #000;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.send-emails-container .send-btn:hover {
  transform: scale(1.1) translateY(-5px);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
}

.placeholder-info {
  font-size: 1rem;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 15px;
  border-left: 3px solid #4285f4;
}

@keyframes rippleIn {
  0% {
    background: rgba(187, 134, 252, 0.2);  /* Your purple theme color */
    transform: scale(1);
  }
  50% {
    background: rgba(187, 134, 252, 0.1);
    transform: scale(1.002);
  }
  100% {
    background: transparent;
    transform: scale(1);
  }
}

.send-emails-container .subject.content-new,
.send-emails-container .email-area.content-new {
  animation: rippleIn 0.8s ease-out forwards;
}
