/* Main container - NO SCROLL */
.scraped-results-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 20px;
}

/* Information bar */
.scraped-results-list .information-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.scraped-results-list .information-bar .search {
  width: 300px;
}

.scraped-results-list .information-bar .search input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.386);
  padding: 10px;
  font-size: 1rem;
  color: #fff;
  width: 100%;
}

.scraped-results-list .information-bar .search input:focus-visible {
  outline: none;
  border-bottom: 1px solid #fff;
}

/* Results container - MAIN SCROLLABLE LIST */
.result-container {
  flex: 1;
  overflow-y: auto; /* Keep main list scrollable */
  padding: 20px;
  padding-top: 0px;
}

/* Group section */
.group-section {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  /* background-color: rgba(17, 17, 17, 0.95); */
  margin-bottom: 8px;
}

/* When expanded, take full height */
.group-section.expanded {
  /* max-height: calc(100vh - 200px); */
  max-height: calc(60vh);
  display: flex;
  flex-direction: column;
}

/* Group header */
.group-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  /* height: 75px; */
  /* height: 50px; */
  /* background-color: rgba(17, 17, 17, 0.98); */
  cursor: pointer;
}

.group-header:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

/* Group info layout */
.group-info {
  display: flex;
  align-items: center;
  /* flex: 1; */
  gap: 12px;
  height: 50px;
}

.group-name {
  font-weight: 600;
  color: #fff;
  font-size: 1.1rem;
}

.group-size{
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.95rem;
}

.group-date {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.95rem;
  margin-left: auto;
  /* padding-right: 32px; */
}

.expand-icon {
  color: rgba(255, 255, 255, 0.4);
  font-size: 1rem;
}

/* Group content - scrollable content when expanded */
.group-content {
  flex: 1;
  overflow-y: auto; /* Scrollable content */
  background-color: rgba(0, 0, 0, 0.2);
}

/* Results header */
.results-header {
  display: flex;
  padding: 15px 24px;
  background-color: rgba(0, 0, 0, 0.95);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.results-header h4 {
  flex: 1;
  margin: 0;
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.85rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

/* Column widths */
.results-header h4:nth-child(1),
.result-entry .result-info:nth-child(1) {
  flex: 1.5;
  padding-left: 60px;
}

.results-header h4:nth-child(2),
.result-entry .result-info:nth-child(2) {
  flex: 2.5;
  padding-right: 30px;
}

.results-header h4:nth-child(3),
.result-entry .result-info:nth-child(3) {
  flex: 1.5;
  padding-right: 20px;
}

/* Result entries */
.result-entry {
  display: flex;
  align-items: center;
  padding: 15px 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  transition: background-color 0.3s ease;
}

.result-entry:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.result-entry:last-child {
  border-bottom: none;
}

/* Result info columns */
.result-entry .result-info {
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* Profile image */
.result-entry .profile-img {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  margin-right: 10px;
  flex-shrink: 0;
}

/* Email format display */
.email-formats {
  background-color: rgba(30, 30, 30, 0.95);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  padding: 8px 12px;
  color: #fff;
  font-size: 0.9rem;
  width: 100%;
  transition: background-color 0.3s ease;
}

.email-formats:hover {
  background-color: rgba(40, 40, 40, 0.95);
  border-color: rgba(255, 255, 255, 0.2);
}

/* Company styling */
.result-entry #company {
  color: #bb86fc;
}

/* Name link */
.result-entry .name {
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
}

.result-entry .name:hover {
  text-decoration: underline;
}

/* No results message */
.no-results {
  text-align: center;
  padding: 40px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 1rem;
}

/* Scrollbar styling */
.group-content::-webkit-scrollbar {
  width: 8px;
}

.group-content::-webkit-scrollbar-track {
  background: transparent;
}

.group-content::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}

.group-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

/* Add these styles for the menu buttons */
.MenuButton {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  width: 100%;
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  transition: all 0.2s ease;
}

.MenuButton:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

/* Style for active menu button */
.MenuButton.active {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  border-left: 3px solid #bb86fc; /* Using the same purple as company text */
}

.button-content {
  display: flex;
  align-items: center;
  gap: 12px;
}

.button-content img {
  width: 20px;
  height: 20px;
  opacity: 0.7;
}

/* When button is active, make icon more visible */
.MenuButton.active .button-content img {
  opacity: 1;
}

.delete-btn {
  background: none;
  border: none;
  cursor: pointer;
  /* padding: 4px 8px; */
  margin-left: 8px;
  border-radius: 4px;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-btn img {
  width: 16px;
  height: 16px;
  /* opacity: 1; */
  transition: opacity 0.2s;
  filter: grayscale(1); /* This will make black icons white */
  /* Alternative method using brightness and contrast:
  filter: brightness(0) invert(1);
  */
}

.delete-btn:hover {
  /* background-color: rgba(255, 0, 0, 0.1); */
}

.delete-btn:hover img {
  filter: none;
  /* opacity: 1; */
}
