.delete-confirmation{
  display: flex;
  flex-direction: column;
  align-items: start;
}


.delete-buttons{
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: end;
}

.modal-cancel-btn {
  background-color: transparent;
  color: #fff;
  /* border: 1px solid #fff; */
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.2s ease;
  opacity: 0.5;
}

.modal-cancel-btn:hover {
  /* background-color: #9a6fe0; */
  transform: translateY(-2px);
  opacity: 1;
}

.modal-delete-btn {
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 8px;
  padding: 10px 20px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.2s ease;
  opacity: 0.5;
}

.modal-delete-btn:hover {
  background-color: red;
  transform: translateY(-2px);
  opacity: 1;
}

.modal-delete-btn:active {
  background-color: #7a54c7;
  transform: translateY(0);
}

.modal-delete-btn:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
}

/* Add container for the buttons */
.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.delete-confirmation h2 {
  margin-bottom: 12px;  /* Increase space after the header */
}

.delete-confirmation p {
  margin-bottom: 5px;  /* Add consistent spacing between paragraphs */
  opacity: 0.4;
}

.delete-confirmation p:last-of-type {
  margin-bottom: 20px;  /* Add more space before the button */
}
